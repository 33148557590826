import React from "react"
import ServicePayment from "../../components/services/ServicePayment"

function AmendmentServicePayment() {
  return (
    <div>
      <ServicePayment />
    </div>
  )
}

export default AmendmentServicePayment
