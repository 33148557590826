import React from "react"
import MyProfileComponent from "../../components/user/MyProfile"

const MyProfile = () => {
  return (
    <div>
      <MyProfileComponent />
    </div>
  )
}

export default MyProfile
