import React from "react"
import LoginPage from "../components/login"

function Login() {
  return (
    <div>
      <LoginPage />
    </div>
  )
}

export default Login
