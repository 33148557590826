import React from "react"
import ArticlesOfAmendment from "../../components/services/ArticlesOfAmendment"

function ArticleOfAmendmentPage() {
  return (
    <div>
      <ArticlesOfAmendment />
    </div>
  )
}

export default ArticleOfAmendmentPage
