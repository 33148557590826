import React from "react"
import MyOrdersComponent from "../../components/user/MyOrders"

const MyOrders = () => {
  return (
    <div>
      <MyOrdersComponent />
    </div>
  )
}

export default MyOrders
