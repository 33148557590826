import React from "react"
import ForgetPass from "../components/forget-pass"

const ForgetPassword = () => {
  return (
    <div>
      <ForgetPass />
    </div>
  )
}

export default ForgetPassword
