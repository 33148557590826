import React from "react"
import ResetPass from "../components/reset-pass"

const ResetPassword = () => {
  return (
    <div>
      <ResetPass />
    </div>
  )
}

export default ResetPassword
